import LexieEncryption from "./LexieEncryption.js";

export default class Parser {
    lexieEncryption = undefined;
    constructor(lexieEncryption) {
        this.lexieEncryption = lexieEncryption;
        if(!this.lexieEncryption.isValidEncryption()) {
            throw "Invalid encryption used";
        }
    }

}