export default  {
    ro: {
        LANG_USERNAME: "Nume utilizator",
        LANG_AUTHENTICATE: 'Autentificare',
        LANG_CONVERSATION: '@utilizator or #canal',
        LANG_MESSAGE: 'Mesaj',
        LANG_ENCRYPT: 'Cripteaza',
        LANG_SET_ENCRYPTION: 'Foloseste criptarea',
        LANG_ENCRYPTION_BYTE: 'Octet pentru criptare',
        LANG_ENCRYPTION_SUM: 'Suma pentru criptare',
        LANG_RESET_ENCRYPTION: 'Reseteaza criptarea',
        LANG_ENCRYPTED: 'Criptat',
        LANG_DELETE: 'Sterge',
        LANG_CANCEL: "Anuleaza",
        LANG_CLOSE_CHANNEL_TITLE: "Doresti sa inchizi aceasta conversatie?",
        LANG_CLOSE_CHANNEL_TEXT: "Apasand butonul STERGE vei incheia aceasta conversatie",
        LANG_DRAFT: "Mesaj: ",
        LANG_MESSAGE_ENCRYPTED: "Mesaj criptat"
    },
    en: {
        LANG_AUTHENTICATE: "Authenticate",
        LANG_USERNAME: 'Username',
        LANG_CONVERSATION: '@username or #channel',
        LANG_MESSAGE: 'Message',
        LANG_ENCRYPT: 'Encrypt',
        LANG_SET_ENCRYPTION: 'Set encryption',
        LANG_ENCRYPTION_BYTE: 'Encryption byte',
        LANG_ENCRYPTION_SUM: 'Encryption sum',
        LANG_RESET_ENCRYPTION: 'Reset encryption',
        LANG_ENCRYPTED: 'Encrypted',
        LANG_DELETE: 'Delete',
        LANG_CANCEL: "Cancel",
        LANG_CLOSE_CHANNEL_TITLE: "Do you want to close this conversation?",
        LANG_CLOSE_CHANNEL_TEXT: "By clicking DELETE you will close this conversation",
        LANG_DRAFT: "Draft: ",
        LANG_MESSAGE_ENCRYPTED: "Ecrypted message"
    }
}
