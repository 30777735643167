import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import InputBase from '@material-ui/core/InputBase';
import IconButton from '@material-ui/core/IconButton';
import SearchIcon from '@material-ui/icons/Search';
import Style from '../Styles/MessageInputStyle';
import SendIcon from '@material-ui/icons/Send';

const useStyles = makeStyles((theme) => (Style(theme)));

export default function MessageInput(props) {
  const classes = useStyles();

  const componentConstants = {
      ...props.componentConstants
  }

  const componentMethods = {
      ...props.componentMethods
  }

  const componentProps = {
      ...props.componentProps
  }
  const handleKeyPress = (event) => {
    if(event.key === 'Enter'){
        event.preventDefault()
        onSend(event)
    }
  }
  const onChange = (event) => {
    let currentMessage = componentMethods.getManagerState().currentMessage
    currentMessage[componentMethods.getManagerState().activeRoom] = event.target.value
    componentMethods.setManagerState({currentMessage:currentMessage})
  }
  const onSend = (event) => {
    let currentMessage = componentMethods.getManagerState().currentMessage
    componentMethods.sendMessage(componentMethods.getManagerState().currentMessage[componentMethods.getManagerState().activeRoom], componentMethods.getManagerState().activeRoom)
    currentMessage[componentMethods.getManagerState().activeRoom] = ''
    componentMethods.setManagerState({currentMessage:currentMessage})
  }
  return (
    <Paper component="form" className={classes.root}>
      <InputBase
        onKeyPress={handleKeyPress}
        multiline={true}
        className={classes.input}
        placeholder={componentConstants.language.LANG_MESSAGE}
        inputProps={{ 'aria-label': 'search google maps' }}
        value={componentMethods.getManagerState().currentMessage[componentMethods.getManagerState().activeRoom]?componentMethods.getManagerState().currentMessage[componentMethods.getManagerState().activeRoom]:''} 
        onChange={(event) => {onChange(event)}}
      />
      <IconButton className={classes.iconButton} aria-label="search" onClick={(event)=>{
          onSend(event)
      }}>
        <SendIcon />
      </IconButton>
    </Paper>
  );
}