
import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import Style from '../Styles/AuthenticationUsernameTextFieldStyle'
const useStyles = makeStyles((theme) => (Style(theme)));

export default function AuthenticationUsernameTextField(props) {
  const classes = useStyles();
  const componentConstants = {
      ...props.componentConstants
  }

  const componentMethods = {
      ...props.componentMethods
  }

  const componentProps = {
      ...props.componentProps
  }
  return (
        <TextField onChange={(event)=>{componentMethods.setManagerState({username: event.target.value.toLowerCase()})}} className={classes.textField} id="username" label={componentConstants.language.LANG_USERNAME} />
  );
}