export default class LexieEncryption {
    zeros = undefined;
    ones = undefined;
    sum = undefined;

    constructor(zeros, ones, sum) {
        this.zeros = zeros
        this.ones = ones
        this.sum = parseInt(sum)
    }

    isValidEncryption() {
        let inces = 0;
        if (this.sum === undefined) {
            return false;
        }
        if (this.zeros === undefined) {
            return false;
        }
        if(this.ones === undefined) {
            return false;
        }

        if(this.sum < 1) {
            return false;
        }

        if (this.zeros.length + this.ones.length === 9) {
            return true;
        }
        return false;
    }

    encrypt(output) {
        let sendOutput = "";
        for(let i in output) {
            if(output[i] === " ") {
                sendOutput += " ";
            } else if(output[i] === "1") {
                sendOutput += this.ones[Math.floor(Math.random() * this.ones.length)];
            } else if(output[i] === "0") {
                sendOutput += this.zeros[Math.floor(Math.random() * this.zeros.length)];
            }
        }
        let encryptArray = sendOutput.split(' ');
        let encryptedArray = [];
        for(let i in encryptArray) {
            encryptedArray[i] = parseInt(encryptArray[i]) + this.sum;
        }
        sendOutput = encryptedArray.join(' ');
        return sendOutput;
    }

    decrypt(output) {
        let sendOutput = '';
        output = parseInt(output) - this.sum;
        output = output + ''
        for (let y = 0; y < output.length; y++) {
            if (this.ones.indexOf(parseInt(output[y])) > -1) {
                sendOutput += '1';
            } else if (this.zeros.indexOf(parseInt(output[y])) > -1) {
                sendOutput += '0';
            }
        }
        return sendOutput;
    }

    encryptObject(object) {
        let nObject = [];
        object.forEach((element, index, array ) => {
            nObject[index] = this.encrypt(object[index])
        })
        return nObject;

    }

    decryptObject(object) {
        let nObject = [];
        object.forEach((element, index, array ) => {
            nObject[index] = this.decrypt(object[index])
        })

        return nObject;
    }


}