import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Style from "../Styles/AuthenticationPaperStyle";
import AuthenticationUsernameTextField from './AuthenticationUsernameTextField';
import AuthenticationButton from './AuthenticationButton';

const useStyles = makeStyles((theme) => (Style(theme)));

export default function AuthenticationPaperStyle(props) {
  const classes = useStyles();

  const componentConstants = {
        ...props.componentConstants
    }

    const componentMethods = {
        ...props.componentMethods
    }

    const componentProps = {
        ...props.componentProps
    }

  return (
    <div>
        <AuthenticationUsernameTextField componentConstants={componentConstants} 
        componentMethods={componentMethods} componentProps={componentProps} />
        <AuthenticationButton componentConstants={componentConstants} componentMethods={componentMethods} />
    </div>  );
}