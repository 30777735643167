import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import Style from '../Styles/MessageListStyle'
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import Avatar from './Avatar';
import Badge from '@material-ui/core/Badge';

import CloseChannelDialog from './CloseChannelDialog';

const useStyles = makeStyles((theme) => (Style(theme)));

export default function MessagesList(props) {
    const classes = useStyles();

    let createdRooms =[]
    let unreadMessagesBadges = {}

    const componentConstants = {
        ...props.componentConstants
    }

    const componentMethods = {
        ...props.componentMethods
    }

    const componentProps = {
        ...props.componentProps
    }
    const getLatestMessage = (roomHash) => {
        let latestMessage = ''
        componentMethods.getManagerState().messages.map((v, i) => {
            if(v.channel === roomHash || v.privateRoom === roomHash) {
                if(v.encrypted) {
                    latestMessage = componentConstants.language["LANG_MESSAGE_ENCRYPTED"]
                } else {
                    latestMessage = v.message
                }
            }
            
        })
        if(componentMethods.getManagerState().currentMessage[roomHash]) {
            latestMessage = componentConstants.language["LANG_DRAFT"] + componentMethods.getManagerState().currentMessage[roomHash]
        }
        return latestMessage
    }
    return (
        <div className={classes.root}>
            <List component="nav" aria-label="main mailbox folders">
            {
                componentMethods.getManagerState().messages.map((v,i) =>{
                    let roomName = v.channel
                    let roomHash = v.channel
                    if(roomName === null) {
                        let privateRoomUsers = v.users
                        for (let userIndex in privateRoomUsers) {
                            if(privateRoomUsers[userIndex] !== componentMethods.getManagerState().username) {
                                roomName = privateRoomUsers[userIndex]
                                roomHash = v.privateRoom
                                break;
                            }
                        }
                    }
                    if(!roomHash  || createdRooms.indexOf(v.privateRoom) > -1 || createdRooms.indexOf(v.channel) > -1){
                        return null
                    } else {
                        createdRooms.push(roomHash)
                        return <ListItem button divider={true} key={i} onClick={() => { componentMethods.setManagerState({activeScreen:'messageContainer', activeRoom:roomHash, titleAppbar: roomName});}}>
                                    <Badge anchorOrigin={{
                                        vertical: 'top',
                                        horizontal: 'left',
                                    }} badgeContent={componentMethods.getManagerState().unreadMessagesBadges[roomHash] ? componentMethods.getManagerState().unreadMessagesBadges[roomHash] : 0} color="secondary">
                                        <Avatar name={roomName === 'status'?roomName:roomName.substr(1)}/>
                                    </Badge>
                                    <ListItemText primary={roomName} secondary={<i>{getLatestMessage(roomHash)}</i>}/>
                                    {
                                    roomHash === 'status'
                                    ?
                                        null
                                    :
                                    <ListItemSecondaryAction>
                                        <CloseChannelDialog componentMethods={componentMethods} componentProps={componentProps} componentConstants={componentConstants}  roomHash={roomHash} />
                                    </ListItemSecondaryAction>
                                    }
                                </ListItem>

                    }
                })
            }
            </List>
      </div>
    );
  }