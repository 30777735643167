import React from 'react';
import CoreComponent from "../../../../Core/CoreComponent";
import MessageList from "./MaterialComponents/MessageList"


export default class MessagesListComponent extends CoreComponent {

    constructor(props) {
        super(props);
        this.loadComponentsProps()
    }

    componentDidMount() {
        super.componentDidMount()
    }

    loadComponentsProps() {
        const that = this
        this.setComponentsConstants('MessagesList', {
            language: that.props.componentMethods.getManagerState().language,
            messages: that.props.componentMethods.getManagerState().messages
        }).setComponentsMethods('MessagesList', {
            setManagerState: (object) => {
                that.props.componentMethods.setManagerState(object)
            },
            getManagerState: () => {
                return that.props.componentMethods.getManagerState()
            },
            websocketSend: (data, type) => {
                that.props.componentMethods.websocketSend(data, type)
            },
            leaveChannel: (channelName) => {
                let messages = that.props.componentMethods.getManagerState().messages
                let newMessages = []
                messages.map((v,i) => {
                    if (channelName !== v.channel && channelName !== v.privateRoom) {
                        newMessages.push(v)
                    }
                })
                
                that.props.componentMethods.setManagerState({messages: newMessages})
                let type = 'leave'
                let leaveObject = {
                    channel: channelName,
                    username: that.props.componentMethods.getManagerState().username,
                    type: 'leave'
                }
                that.props.componentMethods.websocketSend(leaveObject, type)
            }
        })
    }
    setManagerState(object) {
        const that = this
        this.props.componentMethods.setManagerState(object, () => {
            that.refresh()
        })
    }
    getRender() {
        this.loadComponentsProps()
        return <MessageList {...this.buildComponentProps('MessagesList')} />
    }
}
