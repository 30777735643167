import Parser from "./Parser.js";
import LexieEncryption from "./LexieEncryption.js";

export default class StringParser extends Parser {
    string = undefined
    constructor(lexieEncryption, string) {
        super(lexieEncryption)
        this.string = string;

    }

    toByteArray(str) {
        var buf = new TextEncoder().encode(str).buffer;
        var view = new Uint8Array(buf);
        return view;
    }
    fromByteArray(array) {

    }
    encryptString() {
        let bytes = this.toByteArray(this.string);
        let bin = [...bytes].map((n) => n.toString(2).padStart(8, '0'));
        const binEncrypted = this.lexieEncryption.encryptObject(bin)
        return binEncrypted
    }

    decryptByteArray(byteArray) {
        let string = '';
        let bytes = this.lexieEncryption.decryptObject(byteArray)
        let bin = [...bytes].map((n) =>  parseInt(n));

        string = bytes.map((item, i)=>{
            return String.fromCharCode(parseInt(item, 2))
        })
        return string.join('')
    }

    matchesRegexp( byteArray, regexp = /[A-Z0-9a-z\s]/g) {
        let returnString = this.decryptByteArray(byteArray).match(regexp)
        if(returnString && returnString.length){
            return returnString
        } else {
            return false
        }
    }
    encryptToBase64(byteArray) {
        let data = JSON.stringify(byteArray)
        let buff = new Buffer(data);
        let base64data = buff.toString('base64');
        return base64data
    }

    decryptFromBase64(data) {
        let buff = new Buffer(data, 'base64');
        let text = buff.toString('ascii');
        return JSON.parse(text)
    }
}