import React from 'react';
import CoreComponent from "../../../../Core/CoreComponent";
import MessagesListComponent from '../MessagesListComponent/MessagesListComponent'
import AuthenticationComponent from '../AuthenticationComponent/AuthenticationComponent'
import Paper from '@material-ui/core/Paper'
import MessageContainerComponent from '../MessageContainer/MessageContainerComponent'
import TitleAppbarComponent from '../TitleAppbar/TitleAppbarComponent';
import SendMessageContainerComponent from '../SendMessageContainer/SendMessageContainerComponent'

export default class MainScreenComponent extends CoreComponent {

    constructor(props) {
        super(props);
        this.props.setManagerState({activeScreen: 'authenticationPaper'})
        this.loadComponentsProps()
    }

    componentDidMount() {
        super.componentDidMount()
    }
    loadComponentsProps() {
        const that = this
        this.setComponentsConstants('MessagesList', {
            language: that.props.getManagerState().language,
            messages: that.props.getManagerState().messages
        }).setComponentsMethods('MessagesList', {
            setManagerState: (object) => {
                that.props.setManagerState(object)
            },
            getManagerState: () => {
                return that.props.getManagerState()
            },
            websocketSend: (data, type) => {
                that.props.websocketSend(data, type)
            },
            setScrollPosition: (scrollPos) => {
                that.props.setScrollPosition(scrollPos)
            },
        }).setComponentsMethods('MessageContainer', {
            setManagerState: (object) => {
                that.props.setManagerState(object)
            },
            getManagerState: () => {
                return that.props.getManagerState()
            },
            websocketSend: (data, type) => {
                that.props.websocketSend(data, type)
            },
            setScrollPosition: (scrollPos) => {
                that.props.setScrollPosition(scrollPos)
            },
        }).setComponentsConstants('Authentication', {
            language: that.props.getManagerState().language,
            messages: that.props.getManagerState().messages
        }).setComponentsMethods('Authentication', {
            setManagerState: (object) => {
                that.props.setManagerState(object)
            },
            getManagerState: () => {
                return that.props.getManagerState()
            },
            websocketSend: (data, type) => {
                that.props.websocketSend(data, type)
            }
        }).setComponentsConstants('AddConversation', {
            language: that.props.getManagerState().language,
        }).setComponentsMethods('AddConversation', {
            setManagerState: (object) => {
                that.props.setManagerState(object)
            },
            getManagerState: () => {
                return that.props.getManagerState()
            },
            websocketSend: (data, type) => {
                that.props.websocketSend(data, type)
            }
        }).setComponentsConstants('TitleAppbar', {
            language: that.props.getManagerState().language,
        }).setComponentsMethods('TitleAppbar', {
            setManagerState: (object) => {
                that.props.setManagerState(object)
            },
            getManagerState: () => {
                return that.props.getManagerState()
            },
            websocketSend: (data, type) => {
                that.props.websocketSend(data, type)
            },
            setScrollPosition: (scrollPos) => {
                that.props.setScrollPosition(scrollPos)
            },
        }).setComponentsConstants('SendMessageContainerComponent', {
            language: that.props.getManagerState().language,
        }).setComponentsMethods('SendMessageContainerComponent', {
            setManagerState: (object) => {
                that.props.setManagerState(object)
            },
            getManagerState: () => {
                return that.props.getManagerState()
            },
            websocketSend: (data, type) => {
                that.props.websocketSend(data, type)
            },
            setScrollPosition: (scrollPos) => {
                that.props.setScrollPosition(scrollPos)
            },
        })
    }
    getRender() {

        let classes = {
            root: {
              display: 'flex',
              flexWrap: 'wrap',
              margin: '70px auto',
              '& > *': {
                margin: 1,
                width: 16,
                height: 16,
              },
            },
            paper: {
              margin: '10px auto',
              padding: 10,
              width: '100%',
              height: window.innerHeight - 240,
              overflowY:'scroll'
            },
            formContainer: {
              margin: '0 auto'
            }
          };

        this.loadComponentsProps()
        switch(this.props.getManagerState().activeScreen) {
            case 'authenticationPaper':
                return <div style={classes.root}>
                            <TitleAppbarComponent {...this.buildComponentProps('TitleAppbar')} />
                            <Paper style={classes.paper}>
                                <AuthenticationComponent {...this.buildComponentProps('Authentication')} />
                            </Paper>
                        </div>                
                break;
            case 'messageList':
                return <div style={classes.root}>
                            <TitleAppbarComponent {...this.buildComponentProps('TitleAppbar')} />
                            <Paper style={classes.paper} id="scrollPaper" onScroll={this.onScroll}>
                                <MessagesListComponent {...this.buildComponentProps('MessagesList')} />
                            </Paper>
                        </div>     
                break
            case 'messageContainer':
                return <div style={classes.root} id="scrollPaperContainer">
                            <TitleAppbarComponent {...this.buildComponentProps('TitleAppbar')} />
                            <Paper style={classes.paper} id="scrollPaper">
                                <MessageContainerComponent {...this.buildComponentProps('MessageContainer')} />
                            </Paper>
                            <SendMessageContainerComponent {...this.buildComponentProps('SendMessageContainerComponent')} />
                        </div>
                break
            default:
                return <React.Fragment></React.Fragment>
                break
        }
        
    }
}