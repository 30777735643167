import React from "react";
import {
    BrowserRouter as Router,
    Switch,
    Route,
    Link
} from "react-router-dom";
import RouterSwitch from "./RouterSwitch";
import history from './History';
import Languages from "../../../Languages/Languages";
import Settings from '../Settings/Settings'
import { w3cwebsocket as W3CWebSocket } from "websocket";


class RouterComponent extends React.Component {
    constructor(props) {
        super(props);
        let lang = window.location.pathname.split('/')[1]
        if(lang === '' || lang === undefined)
            lang = Languages[Settings.defaultLanguage]
        else
            lang = Languages[lang]
            
        const websocketClient = new W3CWebSocket(Settings.websocketServer);

        websocketClient.onmessage = (message) => {

            let messageData = JSON.parse(message.data)
            let managerState = this.getManagerState()
            if(messageData.type === 'ping') {
                this.setManagerState({pingWebsocket: true})
            } else if (messageData.type === 'userList') {
                let userList = this.getManagerState().userList
                userList[messageData.channel] = messageData.message
                this.setManagerState({userList: userList})
            } else {
                if(managerState.messages === undefined)
                    managerState.messages = []
                let roomHash = messageData.channel
                if(roomHash === null) {
                    roomHash = messageData.privateRoom
                }    
                let unreadMessagesBadges = this.getManagerState().unreadMessagesBadges
                if(messageData.privateRoom !== this.getManagerState().activeRoom && messageData.channel !== this.getManagerState().activeRoom) {
                    if (unreadMessagesBadges[roomHash]) {
                        unreadMessagesBadges[roomHash] += 1;
                    } else {
                        unreadMessagesBadges[roomHash] = 1;
                    }
                }           
                managerState.messages.push(messageData)
                this.setManagerState({messages: managerState.messages, unreadMessagesBadges: unreadMessagesBadges}, () => {
                    if(document.getElementById('scrollPaper') && document.getElementById('messagesContainer')) {
                        if(messageData.privateRoom === this.getManagerState().activeRoom || messageData.channel === this.getManagerState().activeRoom) {
                            if(document.getElementById('scrollPaper').scrollTop > document.getElementById('messagesContainer').clientHeight - 1000) {
                                this.setScrollPosition(document.getElementById('messagesContainer').clientHeight)
                            }
                        }
                    }
                })
                this.websocketOnMessage(messageData)
            }
        };

        this.state = {
            language: lang,
            websocketClient: websocketClient,
            encryption: {},
            showSetEncryption: false,
            conversation: '',
            scrollPosition: {},
            unreadMessagesBadges: {},
            currentMessage: {},
            userList: {},
            pingWebsocket: true,
            messages: []
        }
    }

    setScrollPosition (scrollPositionParam = null) {
        let scrollPaper = document.getElementById('scrollPaper')
        if(this.getManagerState().activeRoom) {
            if(this.getManagerState().scrollPosition[this.getManagerState().activeRoom] === undefined || scrollPaper.scrollTop > this.getManagerState().scrollPosition[this.getManagerState().activeRoom]) {
                let scrollPosition = this.getManagerState().scrollPosition
                let scrollPos = scrollPaper.scrollTop
                if(scrollPositionParam !== null)
                    scrollPos = scrollPositionParam
                scrollPosition[this.getManagerState().activeRoom] = scrollPos
                this.setManagerState({scrollPosition: scrollPosition})
            } else if (scrollPositionParam !== null) {
                let scrollPosition = this.getManagerState().scrollPosition
                scrollPosition[this.getManagerState().activeRoom] = scrollPositionParam
                this.setManagerState({scrollPosition: scrollPosition}, function(){document.getElementById('scrollPaper').scrollTop = scrollPositionParam})
            }
        }
    }

    componentDidMount() {
        this.pingWebsocket()
    }
    setManagerState(stateObject, callback = () => {}) {
        if(stateObject) {
            let that = this
            this.setState(stateObject,() => {
                this.refresh(callback)
            })
        }
    }
    pingWebsocket() {
        var that = this
        setTimeout(() => {
            that.setManagerState({pingWebsocket: false}, () => {
                if(that.getManagerState().messages.length > 0)
                    that.websocketSend({username: that.getManagerState().username, type: "ping"}, "ping")
            })
            that.pingWebsocket()
        }, 3000);
    }
    getManagerState() {
        return this.state
    }
    render() {
        return (
            <Router history={history}>
                <RouterSwitch
                history={history} 
                getManagerState={() => { return this.getManagerState()}}
                websocketSend={(data, type)=>{this.websocketSend(data, type)}}
                setManagerState={(stateObject, callback) => {this.setManagerState(stateObject, callback)}}
                setScrollPosition={(scrollPos) => {this.setScrollPosition(scrollPos)}}/>
            </Router>
        );
    }
    refresh(callback = () => {}) {
        this.forceUpdate()
        callback()
    }
    websocketSend(data, type)
    {
        this.state.websocketClient.send(JSON.stringify({
        ...data,
        type: type
      }));
    }

    websocketOnMessage(message) {
        switch(message.type) {
            case 'connect':
                this.setManagerState({activeScreen:'messageList'})
                break
        }
    }
}

export default RouterComponent