import primaryColor from '@material-ui/core/colors/lightBlue';
import secondaryColor from '@material-ui/core/colors/orange';
import { createMuiTheme, responsiveFontSizes } from '@material-ui/core/styles';
let themeSettings = {
    palette: {
        primary: {
            main:primaryColor[500],
            light:primaryColor[300],
            dark:primaryColor[700],
            contrastText: '#FFF',
        },
        secondary: {
            main:secondaryColor[500],
            light:secondaryColor[300],
            dark:secondaryColor[700],
            contrastText: '#FFF',
        },
        status: {
            danger: 'orange',
        }
    },
    themeName: 'Lexie Themes'
}
let theme = createMuiTheme(themeSettings);
// export default theme;
export default responsiveFontSizes(theme);
export {primaryColor}
export {secondaryColor}