export default function Style(theme) {
    const style = {
        appBar: {
          top: 'auto',
          bottom: 0,
          padding: 10
        },
        root: {
          flexGrow: 1,
        },
        title: {
          flexGrow: 1,
        },
      }
    return style
}