
import React from 'react';
import CoreComponent from "../../../../Core/CoreComponent";
import SendMessageContainer from "./MaterialComponents/SendMessageContainer"
import LexieEncryption from "../../../../Vendor/LexieEncryption/LexieEncryption";
import StringParser from '../../../../Vendor/LexieEncryption/StringParser'

export default class MessagesListComponent extends CoreComponent {

    constructor(props) {
        super(props);
        this.loadComponentsProps()
    }

    componentDidMount() {
        super.componentDidMount()
    }

    loadComponentsProps() {
        const that = this
        this.setComponentsConstants('SendMessageContainer', {
            language: that.props.componentMethods.getManagerState().language,
            messages: that.props.componentMethods.getManagerState().messages
        }).setComponentsMethods('SendMessageContainer', {
            setManagerState: (object) => {
                that.props.componentMethods.setManagerState(object)
            },
            getManagerState: () => {
                return that.props.componentMethods.getManagerState()
            },
            websocketSend: (data, type) => {
                that.props.componentMethods.websocketSend(data, type)
            },
            sendMessage: (message, activeRoom) => {
                this.sendMessage(message, activeRoom)
            }
        })
    }
    sendMessage(message, activeRoom) {
        let isEncrypted = false
        if(this.props.componentMethods.getManagerState().encryption[this.props.componentMethods.getManagerState().activeRoom]) {
            let encryptionObject = this.props.componentMethods.getManagerState().encryption[this.props.componentMethods.getManagerState().activeRoom]
            const encryption = new LexieEncryption(encryptionObject.zeros, encryptionObject.ones, encryptionObject.sum)
            const parser = new StringParser(encryption, message);
            message = parser.encryptString().join(' ')
            isEncrypted = true
        }
        if(activeRoom.substring(0, 1) !== '#') {
            var obj = {
                privateRoom: activeRoom, 
                username:this.props.componentMethods.getManagerState().username, 
                message: message,
                encrypted: isEncrypted
            }
        } else {
            var obj = {
                channel: activeRoom, 
                username:this.props.componentMethods.getManagerState().username, 
                message: message,
                encrypted: isEncrypted
            }
        }
        this.props.componentMethods.websocketSend(obj, 'message')
        this.props.componentMethods.setScrollPosition(document.getElementById('messagesContainer').clientHeight)
    }
    getRender() {
        this.loadComponentsProps()
        return <SendMessageContainer {...this.buildComponentProps('SendMessageContainer')} />

    }
}
