export default function Style(theme) {
    const style = {
        root: {
          '& > *': {
            margin: theme.spacing(1),
            width: '25ch',
          },
        },
        textField: {
          width: '100%',
          marginBottom: 10
        }
      }
    return style
}