import React, { useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Style from '../Styles/MessageListStyle'
import Paper from '@material-ui/core/Paper';
import { Typography } from '@material-ui/core';
import LexieEncryptionDialogComponent from '../../../../../CommonComponents/LexieEncryptionDialog/LexieEncryptionDialogComponent';
import Button from "@material-ui/core/Button";
import LockOpenIcon from '@material-ui/icons/LockOpen';
import LockIcon from '@material-ui/icons/Lock';
import CancelIcon from '@material-ui/icons/Cancel';
import IconButton from '@material-ui/core/IconButton';
import StringParser from '../../../../../Vendor/LexieEncryption/StringParser'
import LexieEncryption from "../../../../../Vendor/LexieEncryption/LexieEncryption";
import Autolinker from 'autolinker';

const useStyles = makeStyles((theme) => (Style(theme)));

export default function MessagesList(props) {
    const classes = useStyles();

    const componentConstants = {
        ...props.componentConstants
    }

    const componentMethods = {
        ...props.componentMethods
    }

    const componentProps = {
        ...props.componentProps
    }
    var autolinker = new Autolinker( {
        newWindow : true,
        truncate  : 30
    } );

    
    useEffect (() => {
        if(componentMethods.getManagerState().activeRoom) {
            if(document.getElementById('scrollPaper').scrollTop === 0) {
                document.getElementById('scrollPaper').scrollTop = componentMethods.getManagerState().scrollPosition[componentMethods.getManagerState().activeRoom]
            } else if(document.getElementById('scrollPaper').scrollTop > document.getElementById('messagesContainer').clientHeight - 500) {
                document.getElementById('scrollPaper').scrollTop = document.getElementById('messagesContainer').clientHeight
            }
        }
    }, [])

    return (
        <div className={classes.root} id="messagesContainer">
            {
            componentMethods.getManagerState().encryption[componentMethods.getManagerState().activeRoom]
            ?
            <Button
                       variant="contained"
                       color="secondary"
                       startIcon={<LockIcon />}
                       onClick={() => {componentMethods.resetEncryption()}}
                     >
                    {componentConstants.language['LANG_RESET_ENCRYPTION']}
               </Button>
               :
               <React.Fragment>
               <Button
                    variant="contained"
                    color="primary"
                    startIcon={<LockOpenIcon />}
                    onClick={() => {componentMethods.showSetEncryption()}}
                    >
                    {componentConstants.language['LANG_SET_ENCRYPTION']}
            </Button>
                {
                    componentMethods.getManagerState().showSetEncryption
                    ?
                    <IconButton onClick={() => {componentMethods.setManagerState({showSetEncryption: false})}}><CancelIcon /></IconButton>
                    :
                    null
                }
                </React.Fragment>
            }
        {
            componentMethods.getManagerState().showSetEncryption
            ?
            <LexieEncryptionDialogComponent componentMethods={componentMethods} componentConstants={componentConstants} componentProps={componentProps} />
            :
            null
        }
            {
                componentMethods.getManagerState().messages.map((v,i) =>{
                    let message = v.message
                    if(v.encrypted && componentMethods.getManagerState().encryption[componentMethods.getManagerState().activeRoom]) {
                        let encryptionObject = componentMethods.getManagerState().encryption[componentMethods.getManagerState().activeRoom]
                        let encryption = new LexieEncryption(encryptionObject.zeros, encryptionObject.ones, encryptionObject.sum)
                        let parser = new StringParser(encryption, v.message);
                        message = parser.decryptByteArray(v.message.split(' '))
                    }
                    if(v.privateRoom === componentMethods.getManagerState().activeRoom || v.channel === componentMethods.getManagerState().activeRoom ){
                        if(v.username === componentMethods.getManagerState().username) {
                            return <React.Fragment key={i}>
                                        <Paper className={classes.messagePaperOwn}>
                                            <Typography variant="body2" gutterBottom>{v.timestamp} - {v.username}</Typography>
                                            {
                                            (v.encrypted && componentMethods.getManagerState().encryption[componentMethods.getManagerState().activeRoom]) || !v.encrypted
                                            ?
                                            <Typography variant="subtitle2" gutterBottom><span  dangerouslySetInnerHTML={{__html: autolinker.link(message)}}></span></Typography>
                                            :
                                            null
                                            }
                                            {v.encrypted
                                            ?
                                            <Typography variant="body2" gutterBottom><em>{componentConstants.language['LANG_ENCRYPTED']}</em></Typography>
                                            :
                                            null}
                                        </Paper>
                                        <div></div>
                                    </React.Fragment>
                        } else if(v.username !== null){
                            return <React.Fragment key={i}>
                                        <Paper className={classes.messagePaperOther}>
                                            <Typography variant="body2" gutterBottom>{v.timestamp} - {v.username}</Typography>
                                            {
                                            (v.encrypted && componentMethods.getManagerState().encryption[componentMethods.getManagerState().activeRoom]) || !v.encrypted
                                            ?
                                            <Typography variant="subtitle2" gutterBottom><span  dangerouslySetInnerHTML={{__html: autolinker.link(message)}}></span></Typography>
                                            :
                                            null
                                            }
                                            {v.encrypted
                                            ?
                                            <Typography variant="body2" gutterBottom><em>{componentConstants.language['LANG_ENCRYPTED']}</em></Typography>
                                            :
                                            null}
                                        </Paper>
                                        <div></div>
                                    </React.Fragment>
                        } else {
                            return <React.Fragment key={i}>
                                        <Paper className={classes.messageSystem}>
                                            <Typography variant="body2" gutterBottom>{v.timestamp} - {v.username}</Typography>
                                            <Typography variant="subtitle2" gutterBottom ><span  dangerouslySetInnerHTML={{__html: autolinker.link(message)}}></span></Typography>
                                        </Paper>
                                        <div></div>
                                    </React.Fragment>
                        }
                        
                    }
                })
            }
      </div>
    );
  }