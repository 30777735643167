import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import IconButton from '@material-ui/core/IconButton';
import CancelIcon from '@material-ui/icons/Cancel';

export default function AlertDialog(props) {
  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div>
    <IconButton edge="end" aria-label="delete" onClick={handleClickOpen}>
        <CancelIcon />
    </IconButton>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{props.componentConstants.language['LANG_CLOSE_CHANNEL_TITLE']}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {props.componentConstants.language['LANG_CLOSE_CHANNEL_TEXT']}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
          {props.componentConstants.language['LANG_CANCEL']}
          </Button>
          <Button onClick={() =>{
              props.componentMethods.leaveChannel(props.roomHash)
              handleClose()
            }} color="secondary" autoFocus>
            {props.componentConstants.language['LANG_DELETE']}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}