import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import IconButton from '@material-ui/core/IconButton';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Style from '../Styles/TitleAppBarStyle';
import SearchInput from './SearchInput';
import UserListDrawer from './UserListDrawer'
const useStyles = makeStyles((theme) => (Style(theme)));

export default function TitleAppBar(props) {
    const classes = useStyles();

    const componentConstants = {
        ...props.componentConstants
    }

    const componentMethods = {
        ...props.componentMethods
    }

    const componentProps = {
        ...props.componentProps
    }
    return (
        <div className={classes.root}>
            <AppBar className={classes.appBar}>
                <Toolbar>
                {
                        componentMethods.getManagerState().activeScreen === 'authenticationPaper'?
                            <React.Fragment>
                                <Typography variant="h6" className={classes.title}>
                                    {componentMethods.getManagerState().titleAppbar}
                                </Typography>
                            </React.Fragment>
                        :
                        null
                    }
                    {
                        componentMethods.getManagerState().activeScreen === 'messageContainer'?
                            <React.Fragment>
                                <IconButton edge="start" className={classes.menuButton} color="inherit" aria-label="menu" onClick={()=>{componentMethods.setScrollPosition(); componentMethods.setManagerState({activeScreen:'messageList', showSetEncryption: false, activeRoom: ''})}}>
                                    <ArrowBackIcon />
                                </IconButton>
                                <Typography variant="h6" className={classes.title}>
                                    {componentMethods.getManagerState().titleAppbar}
                                </Typography>
                                {componentMethods.getManagerState().activeRoom.substring(0,1) ==='#' ?
                                <UserListDrawer componentConstants={componentConstants} componentMethods={componentMethods} componentProps={componentProps} />
                                :
                                null
                                }
                            </React.Fragment>
                        :
                        null
                    }
                    {
                        componentMethods.getManagerState().activeScreen === 'messageList'?
                        <React.Fragment>
                            <SearchInput componentConstants={componentConstants} componentMethods={componentMethods} componentProps={componentProps} />
                        </React.Fragment>
                        :
                        null
                    }
                </Toolbar>
            </AppBar>
        </div>
    );
  }