import React from 'react';
import CoreComponent from "../../../../Core/CoreComponent";
import MessageList from "./MaterialComponents/MessageList"

export default class MessagesListComponent extends CoreComponent {

    constructor(props) {
        super(props);
        this.loadComponentsProps()
        this.setMessagesRead()
    }

    componentDidMount() {
        super.componentDidMount()
    }
    setMessagesRead() {
        let unreadMessagesBadges = this.props.componentMethods.getManagerState().unreadMessagesBadges;
        unreadMessagesBadges[this.props.componentMethods.getManagerState().activeRoom] = 0
        this.props.componentMethods.setManagerState({unreadMessagesBadges: unreadMessagesBadges})
    }
    loadComponentsProps() {

        const that = this
        this.setComponentsConstants('MessagesList', {
            language: that.props.componentMethods.getManagerState().language,
            messages: that.props.componentMethods.getManagerState().messages
        }).setComponentsMethods('MessagesList', {
            setManagerState: (object) => {
                that.props.componentMethods.setManagerState(object)
            },
            getManagerState: () => {
                return that.props.componentMethods.getManagerState()
            },
            websocketSend: (data, type) => {
                that.props.componentMethods.websocketSend(data, type)
            },
            setEncryption: (object) => {
                let encryption = this.props.componentMethods.getManagerState().encryption
                encryption[that.props.componentMethods.getManagerState().activeRoom] = object
                that.props.componentMethods.setManagerState({encryption: encryption, showSetEncryption: false})
            },
            resetEncryption: () => {
                let encryption = that.props.componentMethods.getManagerState().encryption
                delete encryption[that.props.componentMethods.getManagerState().activeRoom]
                that.props.componentMethods.setManagerState({encryption: encryption})
            },
            showSetEncryption: () => {
                that.props.componentMethods.setManagerState({showSetEncryption: true})
            },
            setScrollPosition: (scrollPos) => {
                that.props.componentMethods.setScrollPosition(scrollPos)
            }
        }).setComponentsConstants('SendMessageContainer', {
            language: that.props.componentMethods.getManagerState().language,
            messages: that.props.componentMethods.getManagerState().messages
        }).setComponentsMethods('SendMessageContainer', {
            setManagerState: (object) => {
                that.props.componentMethods.setManagerState(object)
            },
            getManagerState: () => {
                return that.props.componentMethods.getManagerState()
            },
            websocketSend: (data, type) => {
                that.props.componentMethods.websocketSend(data, type)
            },
            sendMessage: (message, activeRoom) => {
                this.sendMessage(message, activeRoom)
            }
        })
    }
    sendMessage(message, activeRoom) {
        if(activeRoom.substring(0, 1) !== '#') {
            var obj = {
                privateRoom: activeRoom, 
                username:this.props.componentMethods.getManagerState().username, 
                message: message
            }
        } else {
            if(activeRoom.substring(0,1) !== '@')

            var obj = {
                channel: activeRoom, 
                username:this.props.componentMethods.getManagerState().username, 
                message: message
            }
        }
        this.props.componentMethods.websocketSend(obj, 'message')
    }
    getRender() {
        this.loadComponentsProps()
        return <React.Fragment>
                    <MessageList {...this.buildComponentProps('MessagesList')} />
                </React.Fragment>

    }
}
