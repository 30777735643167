import React, { useState } from 'react';
import Routes from './Routes'
import {
    Switch,
    Route,
    Redirect
} from "react-router-dom";

export default function RouterSwitch(props) {
    return (<Switch>
                <Routes {...props} />
            </Switch>);
}