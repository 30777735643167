import React from 'react';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import Drawer from '@material-ui/core/Drawer';
import List from '@material-ui/core/List';
import Divider from '@material-ui/core/Divider';
import IconButton from '@material-ui/core/IconButton';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import PeopleAltIcon from '@material-ui/icons/PeopleAlt';
import Style from '../Styles/UserListDrawerStyle';
import AccountCircleIcon from '@material-ui/icons/AccountCircle';
const useStyles = makeStyles((theme) => (Style(theme)));


export default function PersistentDrawerLeft(props) {
  const classes = useStyles();
  const theme = useTheme();
  const [open, setOpen] = React.useState(false);

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

    const componentConstants = {
        ...props.componentConstants
    }

    const componentMethods = {
        ...props.componentMethods
    }

    const componentProps = {
        ...props.componentProps
    }
  return (
    <div className={classes.root}>
        <IconButton onClick={() => { 
                                    handleDrawerOpen()
                                    var obj = {
                                        channel: componentMethods.getManagerState().activeRoom, 
                                        username:componentMethods.getManagerState().username, 
                                        type: 'userList',
                                    }
                                    componentMethods.websocketSend(obj, 'userList')
                                }}>
            <PeopleAltIcon />
        </IconButton>
      <Drawer
        className={classes.drawer}
        variant="persistent"
        anchor="left"
        open={open}
        classes={{
          paper: classes.drawerPaper,
        }}
      >
        <div className={classes.drawerHeader}>
          <IconButton onClick={handleDrawerClose}>
            {theme.direction === 'ltr' ? <ChevronLeftIcon /> : <ChevronRightIcon />}
          </IconButton>
        </div>
        <Divider />
        <List>
          {componentMethods.getManagerState().userList[componentMethods.getManagerState().activeRoom] ?
          componentMethods.getManagerState().userList[componentMethods.getManagerState().activeRoom].map((text, index) => (
            <ListItem key={index}>
              <ListItemIcon><AccountCircleIcon /></ListItemIcon>
              <ListItemText primary={text} />
            </ListItem>
          )) : null }
        </List>
      </Drawer>
    </div>
  );
}