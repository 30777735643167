import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import InputBase from '@material-ui/core/InputBase';
import IconButton from '@material-ui/core/IconButton';
import SearchIcon from '@material-ui/icons/Search';
import Style from '../Styles/SearchInputStyle';
import AccountPopover from './AccountPopover'
import Badge from '@material-ui/core/Badge';

const useStyles = makeStyles((theme) => (Style(theme)));

export default function SearchInput(props) {
  const classes = useStyles();

  const componentConstants = {
      ...props.componentConstants
  }

  const componentMethods = {
      ...props.componentMethods
  }

  const componentProps = {
      ...props.componentProps
  }
  const handleKeyPress = (event) => {
    if(event.key === 'Enter'){
        event.preventDefault()
        componentMethods.addConversation()
    }
  }
  const statusBadgeColor = componentMethods.getManagerState().pingWebsocket?'primary':'secondary'
  return (<React.Fragment>
    <Paper component="form" className={classes.root}>
      <InputBase
        onKeyPress={handleKeyPress}
        className={classes.input}
        placeholder={componentConstants.language.LANG_CONVERSATION}
        inputProps={{ 'aria-label': 'search google maps' }}
        value={componentMethods.getManagerState().conversation} 
        onChange={(event)=>{componentMethods.setManagerState({conversation: event.target.value})}}
      />
      <IconButton className={classes.iconButton} aria-label="search" onClick={(event)=>{componentMethods.addConversation()}}>
        <SearchIcon />
      </IconButton>
    </Paper>
      <Badge color={statusBadgeColor} variant="dot">
        <AccountPopover componentConstants={componentConstants} componentMethods={componentMethods} componentProps={componentProps} />
      </Badge>
    </React.Fragment>
  );
}