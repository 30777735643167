import React from 'react';
import CoreComponent from "../../../../Core/CoreComponent";
import AuthenticationPaper from './MaterialComponents/AuthenticationPaper'

export default class AuthenticationComponent extends CoreComponent {

    constructor(props) {
        super(props);
        this.loadComponentsProps()
    }

    componentDidMount() {
        super.componentDidMount()
    }

    loadComponentsProps() {
        const that = this
        this.setComponentsConstants('AuthenticationPaper', {
            language: this.props.componentMethods.getManagerState().language,
        }).setComponentsMethods('AuthenticationPaper', {
            setManagerState: (object) => {
                that.props.componentMethods.setManagerState(object)
            },
            getManagerState: () => {
                return this.props.componentMethods.getManagerState()
            },
            websocketSend: (data, type) => {
                this.props.componentMethods.websocketSend(data, type)
            },
            authenticate: () => {
                this.authenticate()
            }
        })
    }

    authenticate() {
        let username = this.props.componentMethods.getManagerState().username
        if(username.substring(0, 1) !== '@') {
            username = '@' + username
            this.props.componentMethods.setManagerState({username: username})
        }
        this.props.componentMethods.websocketSend({username:username}, 'connect')
    }
    getRender() {
        this.loadComponentsProps()
        return <AuthenticationPaper {...this.buildComponentProps('AuthenticationPaper')} />;
    }
}