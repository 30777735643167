export default function Style(theme) {
    const style = {
        root: {
          flexGrow: 1,
        },
        menuButton: {
          marginRight: theme.spacing(2),
        },
        textField: {
          flexGrow: 1
        },
        addConversationFragment: {
          flexGrow: 1
        },
        appBar: {
          position: 'fixed',
          top: 0,
          left: 0
        }
      }
    return style
}