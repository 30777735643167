import AuthenticationComponent from '../CommonComponents/AuthenticationComponent/AuthenticationComponent'
import MainScreenComponent from '../CommonComponents/MainScreenComponent/MainScreenComponent'

import {
    BrowserRouter as Router,
    Switch,
    Route,
    Link
} from "react-router-dom";
import React from 'react';

export default function Routes(props) {
    return <React.Fragment>
                <Route path={'/'}>
                    <MainScreenComponent {...props} />
                </Route>
            </React.Fragment>
}