import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import TextField from "@material-ui/core/TextField";
import Style from "../Styles/EncryptionInputTextFieldStyle";
const useStyles = makeStyles((theme) => (Style(theme)));

export default function OnesButton (props) {
    const classes = useStyles();

    const componentConstants = {
        ...props.componentConstants
    }

    const componentProps = {
        ...props.componentProps,
        className: classes.root
    }

    return <TextField {...componentProps} {...props.componentProps} />
}