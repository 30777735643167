import React from 'react';
import CoreComponent from "../../../../Core/CoreComponent";
import TitleAppBar from './MaterialComponents/TitleAppBar';


export default class TitleAppbarComponent extends CoreComponent {

    constructor(props) {
        super(props);
        this.loadComponentsProps()
    }

    componentDidMount() {
        super.componentDidMount()
    }
    
    loadComponentsProps() {
        const that = this
        this.setComponentsConstants('TitleAppBar', {
            language: that.props.componentMethods.getManagerState().language,
            messages: that.props.componentMethods.getManagerState().messages
        }).setComponentsMethods('TitleAppBar', {
            setManagerState: (object) => {
                that.props.componentMethods.setManagerState(object)
            },
            getManagerState: () => {
                return that.props.componentMethods.getManagerState()
            },
            websocketSend: (data, type) => {
                that.props.componentMethods.websocketSend(data, type)
            },
            addConversation: () => {
                that.addConversation()
            },
            setScrollPosition: (scrollPos) => {
                that.props.componentMethods.setScrollPosition(scrollPos)
            }
        })
    }


    addConversation() {
        if(this.props.componentMethods.getManagerState().conversation.substring(0, 1) === '#') {
            var data = {
                username: this.props.componentMethods.getManagerState().username,
                type: 'join',
                channel: this.props.componentMethods.getManagerState().conversation
            }
            var type = 'join'
        } else if(this.props.componentMethods.getManagerState().conversation.substring(0, 1) === '@') {
            var data = {
                username: this.props.componentMethods.getManagerState().username,
                username1: this.props.componentMethods.getManagerState().username,
                username2: this.props.componentMethods.getManagerState().conversation,
                type: 'privateRoom'
            }
            var type = 'privateRoom'
        }
        this.props.componentMethods.websocketSend(data, type)
        this.props.componentMethods.setManagerState({conversation:''})
    }

    getRender() {
        this.loadComponentsProps()
        return <TitleAppBar {...this.buildComponentProps('TitleAppBar')} />
    }
}
