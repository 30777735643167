import {stringToHslColor} from '../../../../../Helpers/Helpers';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import color from 'color';
import Avatar from '@material-ui/core/Avatar';

export default function AvatarChannel(props) {
    let colorAvatar = color(stringToHslColor(props.name,45,75));
    return <ListItemAvatar>
                <Avatar  style={{backgroundColor:colorAvatar.rgb(), color: (colorAvatar.isDark()?"#fff":"#000")}}>{props.name[0]}</Avatar>
            </ListItemAvatar>


}