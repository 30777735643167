export default function Style(theme) {
    const style = {
        root: {
          width: '100%',
          overflowY:'scroll'
        },
        messagePaperOwn: {
          margin: '10px auto',
          padding: 10,
          width: 'auto',
          textAlign: 'right',
          backgroundColor: '#33bfff'
        },
        messagePaperOther: {
          margin: '10px auto',
          padding: 10,
          width: 'auto',
          textAlign:'left',
          backgroundColor:'#33eb91'
        },
        messageSystem: {
          margin: '10px auto',
          padding: 10,
          width: 'auto',
          textAlign:'left',
          backgroundColor:'#ffa733'
        },
        formContainer: {
          margin: '0 auto'
        }
      }
    return style
}