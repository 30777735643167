import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Style from "../Styles/AuthenticationButtonStyle";

const useStyles = makeStyles((theme) => (Style(theme)));

export default function AuthenticationButton(props) {
  const classes = useStyles();

  const componentConstants = {
        ...props.componentConstants
    }

    const componentMethods = {
        ...props.componentMethods
    }

    const componentProps = {
        ...props.componentProps
    }
  return (
            <Button variant="contained" color="secondary" className={classes.button} onClick={(event)=>{componentMethods.authenticate()}}>
                {componentConstants.language.LANG_AUTHENTICATE}
            </Button>
  );
}