export default function Style(theme) {
    const style = {
        root: {
          display: 'flex',
          width: '50%',
          flexWrap: 'wrap',
          margin: '0 auto',
          '& > *': {
            margin: theme.spacing(1),
            width: theme.spacing(16),
            height: theme.spacing(16),
          },
        },
        paper: {
          margin: '10px auto',
          padding: 10,
          width: '100%'
        },
        formContainer: {
          margin: '0 auto'
        }
      }
    return style
}