import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Button from "@material-ui/core/Button";
import Style from "../Styles/SetEncryptionValuesButtonStyle";
import Switch from '@material-ui/core/Switch';
const useStyles = makeStyles((theme) => (Style(theme)));

export default function BytesButton (props) {
    const classes = useStyles();

    const componentConstants = {
        ...props.componentConstants,
    }

    const componentMethods = {
        ...props.componentMethods
    }
    const componentProps = {
        ...props.componentProps,
        className: classes.root,
    }

    return <React.Fragment>
                {componentConstants.labelLeft}
                <Switch
                    {...componentProps}
                 />
                {componentConstants.labelRight}
            </React.Fragment>
}
